<template>
    <a-alert v-if="rejectionError" :message="rejectionError" />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { AAlert } from '@/components/AAlert';

type DistributionResult = {
    rejected: boolean;
    rejectionError: string | null;
};

const AmpDistributionResultProps = Vue.extend({
    name: 'AmpDistributionResult',
    props: {
        result: {
            type: String,
            default() {
                return '{}';
            }
        }
    }
});

@Component({
    components: { AAlert }
})
export default class AmpDistributionResult extends AmpDistributionResultProps {
    get rejectionError() {
        let distributionResult: DistributionResult = {
            rejected: false,
            rejectionError: null
        };

        if (this.result) {
            try {
                distributionResult = JSON.parse(this.result);
            } catch {
                /* silently */
            }

            if (distributionResult.rejected) {
                return distributionResult.rejectionError;
            }
        }

        return null;
    }
}
</script>
